<template>
  <div class="invite">
    <h5header :name="$t('home.desc6')" :right="false" />
    <div class="inviteContent">
      <!-- <div class="invite-tab flex">
        <div class="tab-info flex">
          <div :class="{ acttab: act == 0 }" @click="handlerTab(0)">{{ $t('invite.desc44') }}</div>
          <div :class="{ acttab: act == 1 }" @click="handlerTab(1)">{{ $t('invite.desc45') }}</div>

        </div>
        <div class="tab-right flex" v-if="act == 0">
          <div class="flex" @click="handlerId(1)"><img src="../../public/images/new/inviteicon1.png" /> {{
            $t('invite.desc46') }}</div>
          <div class="flex" @click="handlerId(2)"><img src="../../public/images/new/inviteicon2.png" /> {{
            $t('invite.desc47') }}</div>
          <div class="flex" @click="handlerId(3)"><img src="../../public/images/new/inviteicon3.png" /> {{
            $t('invite.desc48') }}</div>
          <div class="flex" @click="handlerId(4)"><img src="../../public/images/new/inviteicon4.png" /> {{
          $t('invite.desc49') }}</div>
        </div>
      </div> -->
      <div class="invite-con" v-if="act == 1">
        <div class="invite-top flex animate__animated animate__fadeInUp">
          <div class="top-left">
            <div class="left-name">{{ $t('invite.desc1') }}</div>
            <div class="left-desc">{{ $t('invite.desc2') }}</div>
            <div class="left-btn flexcenter" @click="showInvite = true">{{ $t('invite.desc3') }}</div>
          </div>
          <img src="../../public/images/new/inviteimg.png" alt="" class="rightimg" />
        </div>
        <div class="invite-name animate__animated animate__fadeInUp">{{ $t('invite.desc4', {
          n1: info.currentLevelName,
          n2: info.currentLevel
        }) }}</div>
        <div class="invite-info flex animate__animated animate__fadeInUp animate__delay-.4s">
          <div class="info-left">
            <div class="level-name">{{ $t('invite.desc5') }}</div>
            <div class="level-line">
              <p :style="{ width: info.nextRankPer + '%' }"></p>
            </div>
            <div class="level-rate flex">
              <div>{{ $t('invite.desc6') }}</div>
              <p>{{ info.nextRankPer || 0 }}%</p>
            </div>
            <div class="level-list flex">
              <div class="list-item">
                <div>{{ info.current1GenerationCount || 0 }}/{{ info.next1GenerationCount || 0 }}</div>
                <p>{{ $t('invite.desc7') }}</p>
              </div>
              <div class="list-item">
                <div>{{ info.current2And3GenerationCount || 0 }}/{{ info.next2And3GenerationCount || 0 }}</div>
                <p>{{ $t('invite.desc8') }}</p>
              </div>
              <div class="list-item">
                <div>{{ info.nextMaxLandLevelName || 0 }}</div>
                <p>{{ $t('invite.desc9') }}</p>
              </div>
            </div>
          </div>
          <div class="info-right">
            <div class="right-top flex">
              <div>{{ $t('invite.desc10') }}</div>
              <p class="flex" @click="$router.push(`/invite/info/${info.userId}?id=${info.userId}&level=1`)">{{
                $t('invite.desc11') }} <img src="../../public/images/new/righticon.png" alt="" /></p>
            </div>
            <div class="level-list flex">
              <div class="list-item">
                <div>{{ teaminfo.teamCount || 0 }}</div>
                <p>{{ $t('invite.desc12') }}</p>
              </div>
              <div class="list-item">
                <div>{{ teaminfo.teamStarPlanetCount || 0 }}</div>
                <p>{{ $t('invite.desc13') }}</p>
              </div>
              <div class="list-item">
                <div>{{ teaminfo.teamStarLandCount || 0 }}</div>
                <p>{{ $t('invite.desc14') }}</p>
              </div>
              <div class="list-item">
                <div>{{ teaminfo.teamAchievement || 0 }}</div>
                <p>{{ $t('invite.desc15') }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="invite-node animate__animated animate__fadeInUp animate__delay-1s">
          <div class="node-name">{{ $t('invite.desc16') }}</div>
          <div class="level-list flex">
            <div class="list-item">
              <div>{{ planetInfo.planetCount || 0 }}</div>
              <p>{{ $t('invite.desc17') }}</p>
            </div>
            <div class="list-item">
              <div>{{ planetInfo.totalInvestAmount || 0 }}</div>
              <p>{{ $t('invite.desc18') }}</p>
            </div>
            <div class="list-item">
              <div>{{ planetInfo.toBeEffectCount || 0 }}</div>
              <p>{{ $t('invite.desc19') }}</p>
            </div>
            <div class="list-item">
              <div>{{ planetInfo.discardCount || 0 }}</div>
              <p>{{ $t('invite.desc20') }}</p>
            </div>
            <div class="list-item">
              <div>{{ planetInfo.finishCount || 0 }}</div>
              <p>{{ $t('invite.desc21') }}</p>
            </div>
            <div class="list-item">
              <div>{{ planetInfo.inEffectCount || 0 }}</div>
              <p>{{ $t('invite.desc22') }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="invite-wrappar" v-else>
        <Activity @invitebtn="invitebtn" ref="actActivity" />
      </div>
    </div>
    <!-- tishi -->
    <el-dialog v-model="showInvite" :append-to-body="true" top="5vh" :show-close="false">
      <div class="invite-modal">
        <img src="../../public/images/new/closeicon.png" alt="" class="close-icon" @click="showInvite = false" />
        <div class="modalwrappar" ref="invitecon">
          <div class="modal-con">
            <div class="invite-logo">
              <img src="../../public/images/logo.png" alt="" />
            </div>
            <div class="invite-name">{{ $t('invite.desc23') }}</div>
            <div class="invite-ewm">
              <div class="ewmbg pc"><vue-qr :text="address" :size="215" :margin="5" :callback="qrBack">
                </vue-qr>
              </div>
              <div class="ewmbg h5"><vue-qr :text="address" :size="150" :margin="5" :callback="qrBack">
                </vue-qr>
              </div>
            </div>
            <div class="invite-tips">
              <div>{{ $t('sign.desc27') }}</div>
              <p class="flex">{{ info.inviteCode }} <img src="../../public/images/new/copy2.png" alt=""
                  @click="copy($event, info.inviteCode)" /></p>
            </div>
            <div class="invite-step">
              <div>{{ $t('invite.desc24') }}</div>
              <p>{{ linkUrl }}</p>
            </div>
            <div class="invite-line flex">
              <div>{{ $t('invite.desc25') }}</div>
              <img src="../../public/images/new/righticon5.png" alt="" />
              <div>{{ $t('sign.desc2') }}</div>
              <img src="../../public/images/new/righticon5.png" alt="" />
              <div>{{ $t('invite.dec26') }}</div>
              <img src="../../public/images/new/righticon5.png" alt="" />
              <div>{{ $t('asset.desc79') }}</div>
            </div>
          </div>
        </div>
        <div class="invite-btn flex">
          <div class="flexcenter" @click="toImage">{{ $t('invite.desc27') }}</div>
          <div class="flexcenter" @click="copy($event, linkUrl)">{{ $t('invite.desc28') }}</div>
        </div>
      </div>
    </el-dialog>


  </div>
</template>

<script>
import VueQr from 'vue-qr/src/packages/vue-qr.vue';
import h5header from '@/componets/h5header.vue';
import glFooter from '@/componets/footer.vue'
import clipboard from '@/utils/libs/clipboard'
import html2canvas from 'html2canvas';
import Activity from './activity.vue';
export default {
  components: {
    glFooter,
    h5header,
    VueQr,
    Activity
  },
  data() {
    return {
      info: {},
      teaminfo: {},
      showInvite: false,
      address: '',
      qrData: '',
      linkUrl: '',
      planetInfo: {},
      act: 1,
      topId: 0,
      bannerBg: ''
    }
  },
  mounted() {

    this.$post(this.URL.invite.planet, {}).then(res => {
      if (res.code == 0) {
        this.planetInfo = res.data;
      }
    })
    this.$post(this.URL.invite.info, {}).then(res => {
      if (res.code == 0) {
        let nextRankPer = parseFloat(res.data.nextRankPer) * 100
        res.data.nextRankPer = nextRankPer.toFixed(0)
        this.info = res.data;
        this.address = this.info.inviteCode;
        this.linkUrl = document.location.origin + '/register?inviteCode=' + this.info.inviteCode
      } else {
        this.$message(res.message)
      }
    })
    this.$post(this.URL.invite.team, {}).then(res => {
      if (res.code == 0) {
        this.teaminfo = res.data;
      } else {
        this.$message(res.message)
      }
    })
    // this.$post(this.URL.home.banner, {
    //   positionKey: "INVITATION_PICTURE",
    // }).then(res => {
    //   if (res.code == 0) {
    //     this.bannerBg = res.data[0].imgUrl
    //   }
    // })
  },
  methods: {

    invitebtn() {
      this.showInvite = true;
    },
    handlerId(id) {
      this.$refs.actActivity.handlerTop(id)
    },
    handlerTab(i) {
      this.act = i;
    },
    toImage() {
      html2canvas(this.$refs.invitecon, {
        useCORS: true
      }).then((canvas) => {
        let dataURL = canvas.toDataURL("image/png");
        this.imgUrl = dataURL;
        if (this.imgUrl !== "") {
          const a = document.createElement("a");
          a.href = this.imgUrl;
          a.download = this.$t("sign.desc27");
          a.click();
        }
      });
    },
    copy(e, text) {
      clipboard(e, text)
      this.$message.success(this.$t('planet.desc61'))
    },
    qrBack(dataUrl, id) { // 缓存二维码数据
      this.qrData = dataUrl
    },
  }
}
</script>

<style lang="less" scoped>
::v-deep .van-popup {
  overflow-y: initial;
}

.invite {
  padding: 96px 0 80px;
  background: url('../../public/images/new/landbg.png') center no-repeat;
  background-size: 100% 100%;

  // .inviteContent{
  //   width: 100%;
  //   background: url('../../public/images/new/invitebg.png') top no-repeat;
  //   background-size: 100% 720px;
  // }
  .invite-tab {
    width: 1240px;
    padding: 22px 0;
    margin: 0 auto;
    border-bottom: 1px solid rgba(238, 238, 238, 0.2);

    .tab-info {
      flex: 1;

      div {
        margin-right: 36px;
        font-size: 16px;
        line-height: 36px;
        color: rgba(255, 255, 255, 0.6);
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          position: relative;
          color: #FFFFFF;

          &::after {
            content: '';
            position: absolute;
            bottom: -22px;
            left: 0;
            width: 100%;
            height: 2px;
            background: #C9FA5B;
          }
        }
      }

      .acttab {
        position: relative;
        color: #FFFFFF;

        &::after {
          content: '';
          position: absolute;
          bottom: -22px;
          left: 0;
          width: 100%;
          height: 2px;
          background: #C9FA5B;
        }
      }
    }

    .tab-right {
      flex: 1;
      justify-content: flex-end;

      div {
        flex: 0 0 116px;
        height: 36px;
        margin-right: 16px;
        justify-content: center;
        line-height: 36px;
        font-size: 13px;
        color: #FFFFFF;
        border-radius: 90px;
        background: #181818;
        cursor: pointer;

        img {
          width: 20px;
          height: 20px;
          margin: 8px 4px 0 0;

        }
      }
    }
  }



  .invite-con {
    width: 1240px;
    margin: 0 auto;

    .invite-top {
      justify-content: space-between;
      height: 347px;
      overflow: hidden;

      .top-left {
        flex: 0 0 666px;
        margin-top: 50px;

        .left-name {
          font-size: 24px;
          color: #FFFFFF;
        }

        .left-desc {
          margin: 16px 0 32px;
          font-size: 16px;
          color: rgba(255, 255, 255, 0.8);
        }

        .left-btn {
          width: 208px;
          height: 52px;
          background: url('../../public/images/new/btnbg5.png') center no-repeat;
          background-size: 100% 100%;
          font-size: 16px;
          color: #000000;
          cursor: pointer;
        }
      }

      .rightimg {
        flex: 0 0 453px;
        width: 453px;
        height: 400px;
      }
    }

    .invite-name {
      font-size: 18px;
      color: #FFFFFF;
    }

    .invite-info {
      margin: 20px 0;
      justify-content: space-between;

      .info-left {
        flex: 0 0 480px;
        padding: 24px;
        border-radius: 12px;
        background: #181818;

        .level-name {
          font-size: 16px;
          color: #FFFFFF;
        }

        .level-line {
          width: 100%;
          height: 8px;
          margin: 22px 0 9px;
          background: #0F0F0F;
          border-radius: 8px;
          overflow: hidden;

          p {
            height: 8px;
            border-radius: 8px;
            background: #C9FA5C;
          }
        }

        .level-rate {
          justify-content: space-between;
          font-size: 14px;
          color: #FFFFFF;

          div {
            color: rgba(255, 255, 255, 0.6);
          }
        }

        .level-list {
          margin-top: 19px;
          justify-content: space-between;

          .list-item {
            font-size: 16px;
            color: #FFFFFF;

            p {
              margin-top: 10px;
              font-size: 13px;
              color: rgba(255, 255, 255, .6);
            }
          }
        }
      }

      .info-right {
        flex: 0 0 740px;
        padding: 24px;
        border-radius: 12px;
        background: #181818;

        .right-top {
          justify-content: space-between;
          font-size: 18px;
          color: #fff;
          line-height: 18px;

          p {
            font-size: 16px;
            cursor: pointer;

            img {
              width: 5px;
              height: 8px;
              margin: 5px 0 0 4px;
            }
          }
        }

        .level-list {
          margin-top: 78px;
          justify-content: space-between;

          .list-item {
            font-size: 16px;
            color: #FFFFFF;

            p {
              margin-top: 10px;
              font-size: 13px;
              color: rgba(255, 255, 255, .6);
            }
          }
        }
      }
    }

    .invite-node {
      padding: 24px;
      border-radius: 12px;
      background: #181818;

      .node-name {
        font-size: 18px;
        color: #FFFFFF;
      }

      .level-list {
        margin-top: 26px;
        justify-content: space-between;

        .list-item {
          font-size: 16px;
          color: #FFFFFF;

          p {
            margin-top: 10px;
            font-size: 13px;
            color: rgba(255, 255, 255, .6);
          }
        }
      }
    }
  }



}
.invite-modal {
    position: relative;
    width: 558px;
    height: 805px;

    .close-icon {
      position: absolute;
      top: 35px;
      right: 35px;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
    .modalwrappar{
      background: url('../../public/images/new/invitebg.png') center no-repeat;
      background-size: 100% 100%;
    }
    .modal-con {
      width: 558px;
      height: 805px;
      //background-color: #181818;
      // background: url('../../public/images/new/shengdanbg.png') center no-repeat;
      // background-size: 100% 100%;
    }

    .invite-logo {
      padding: 35px 0 16px;
      text-align: center;

      img {
        height: 36px;
      }
    }

    .invite-name {
      font-size: 28px;
      color: #FFFFFF;
      text-align: center;
    }

    .invite-ewm {
      position: relative;
      width: 260px;
      margin: 50px auto 12px;
      padding: 22px 0;
      background: rgba(2, 3, 0, 0.9);
      border-radius: 16px;

      // &::after {
      //   content: '';
      //   position: absolute;
      //   top: -22px;
      //   left: 50%;
      //   width: 145px;
      //   height: 52px;
      //   background: url('../../public/images/new/shengdanling.png') center no-repeat;
      //   background-size: 100% 100%;
      //   transform: translateX(-50%);
      // }

      // &::before {
      //   content: '';
      //   position: absolute;
      //   bottom: -11px;
      //   left: 50%;
      //   width: 226px;
      //   height: 24px;
      //   background: url('../../public/images/new/shengdanimg.png') center no-repeat;
      //   background-size: 100% 100%;
      //   transform: translateX(-50%);
      // }

      .ewmbg {
        width: 215px;
        height: 215px;
        margin: 0 auto;
        background: #fff;
      }

      .h5 {
        display: none;
      }
    }

    .invite-tips {
      div {
        font-size: 16px;
        color: rgba(255, 255, 255, 0.8);
        text-align: center;
      }

      p {
        margin-top: 10px;
        justify-content: center;
        font-size: 32px;
        color: #C9FA5B;
        line-height: 32px;

        img {
          width: 20px;
          height: 20px;
          margin: 6px 0 0 6px;
          cursor: pointer;
        }
      }
    }

    .invite-step {
      margin: 34px auto 24px;
      width: 456px;
      padding: 20px 24px;
      background: rgba(2, 3, 0, 0.8);
      border-radius: 12px;
      font-size: 14px;
      color: #FFFFFF;
      text-align: center;
      line-height: 14px;

      p {
        margin-top: 12px;
      }
    }

    .invite-line {
      margin-top: 20px;
      justify-content: center;
      font-size: 13px;
      color: rgba(255, 255, 255, 0.6);
      line-height: 13px;
      padding: 0 5px;

      img {
        width: 12px;
        height: 5px;
        margin: 5px 4px 0;
      }
    }

    .invite-btn {
      position: absolute;
      bottom: 42px;
      left: 0;
      width: 100%;
      justify-content: center;

      div {
        flex: 0 0 210px;
        height: 52px;
        background: url('../../public/images/new/btnbg14.png');
        background-size: 100% 100%;
        color: #FFFFFF;
        font-size: 16px;
        cursor: pointer;

        &:last-child {
          margin-left: 20px;
          background: url('../../public/images/new/btnbg15.png');
          background-size: 100% 100%;
          color: #000000;
        }
      }
    }
  }
@media (max-width:1200px) {

  .invite {
    padding: 68px 16px 80px;

    .invite-tab {
      width: 100%;

      .tab-info {
        div {
          margin-right: 16px;
        }
      }

      .tab-right {
        div {
          margin-right: 10px;
        }
      }
    }

    .invite-con {
      width: 100%;

      .invite-top {
        height: initial;
        overflow: initial;

        .top-left {
          flex: 1;
        }
      }

      .invite-info {
        flex-wrap: wrap;

        .info-left {
          flex: 0 0 100%;
        }

        .info-right {
          margin-top: 20px;
          flex: 0 0 100%;
        }
      }
    }
  }
}

@media (max-width:767px) {
  ::v-deep .el-dialog{
    width: calc(100vw - 8px);
  }
  .invite {
    padding: 56px 16px 100px;

    // .inviteContent{
    //   background: none;
    // }
    .invite-wrappar {
      background: url('../../public/images/new/activubg.png') top no-repeat;
      background-size: 100% 296px;
    }

    .invite-tab {
      margin-bottom: 16px;
      ;
      padding: 16px 0;
      width: 100%;

      .tab-info {
        font-size: 14px;

        div {
          margin-right: 32px;
          line-height: 14px;

          &:hover {
            &::after {
              bottom: -16px;
            }
          }
        }

        .act {
          &::after {
            bottom: -16px;
          }
        }
      }

      .tab-right {
        display: none;
      }
    }

    .invite-con {
      width: 100%;

      .invite-top {
        .top-left {
          flex: 1;
          margin-top: 0;

          .left-name {
            font-size: 20px;
          }

          .left-desc {
            margin: 16px 0 20px;
            font-size: 14px;
          }
        }

        .rightimg {
          display: none;
        }
      }

      .invite-name {
        margin-top: 32px;
        font-size: 14px;
      }

      .invite-info {
        flex-wrap: wrap;

        .info-left {
          flex: 0 0 100%;
          padding: 20px 16px;

          .level-list {
            .list-item {
              font-size: 14px;

              p {
                font-size: 12px;
              }
            }
          }
        }

        .info-right {
          margin-top: 20px;
          flex: 0 0 100%;
          padding: 20px 16px;

          .right-top {
            font-size: 16px;
          }

          .level-list {
            margin-top: 20px;

            .list-item {
              font-size: 14px;

              p {
                font-size: 12px;
              }
            }
          }
        }
      }

      .invite-node {
        padding: 20px 16px;

        .node-name {
          font-size: 16px;
        }

        .level-list {
          flex-wrap: wrap;

          .list-item {
            flex: 0 0 33.3%;
            font-size: 14px;

            p {
              font-size: 12px;
            }

            &:nth-child(-n + 3) {
              margin-bottom: 16px;
            }

            &:nth-child(2),
            &:nth-child(5) {
              text-align: center;
            }

            &:nth-child(3n) {
              text-align: right;
            }
          }
        }
      }
    }

  
  }
  .invite-modal {
      width: 100%;
      height: 578px;

      .modal-con {
        width: 100%;
        height: 578px;

        .invite-logo {
          padding: 24px 0 12px;

          img {
            height: 24px;
          }
        }

        .invite-name {
          font-size: 20px;
        }

        .invite-ewm {
          margin: 24px auto 12px;
          width: 180px;
          padding: 15px 0;

          // &::after {
          //   content: '';
          //   position: absolute;
          //   top: -12px;
          //   left: 50%;
          //   width: 90px;
          //   height: 32px;
          //   background: url('../../public/images/new/shengdanling.png') center no-repeat;
          //   background-size: 100% 100%;
          //   transform: translateX(-50%);
          // }

          // &::before {
          //   content: '';
          //   position: absolute;
          //   bottom: -11px;
          //   left: 50%;
          //   width: 170px;
          //   height: 18px;
          //   background: url('../../public/images/new/shengdanimg.png') center no-repeat;
          //   background-size: 100% 100%;
          //   transform: translateX(-50%);
          // }

          .ewmbg {
            width: 150px;
            height: 150px;
          }

          .pc {
            display: none;
          }

          .h5 {
            display: block;
          }
        }

        .invite-tips {
          font-size: 13px;

          p {
            margin-top: 4px;
            font-size: 24px;
            line-height: 24px;

            img {
              width: 16px;
              height: 16px;
              margin: 4px 0 0 4px;
            }
          }
        }

        .invite-step {
          margin: 12px auto 16px;
          width: calc(100% - 8px);
          padding: 12px;
          font-size: 12px;
        }

        .invite-line {
          font-size: 12px;
          line-height: 12px;

          img {
            margin: 3px 2px;
          }
        }
      }

      .invite-btn {
        width: calc(100% - 8px);
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);

        div {
          flex: 0 0 48%;

          &:last-child {
            margin-left: 4%;
          }
        }
      }

      .close-icon {
        top: 16px;
        right: 16px;
      }
    }
}
</style>